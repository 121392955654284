





























import Vue from 'vue';
import { Component, Provide } from 'vue-property-decorator';
import Big from 'big.js';

import { InvoiceCreator } from '@/components/invoicecreator';
import SendInvoiceModal from '@/components/SendInvoiceModal.vue';
import LightspeedCreatorService from '@/lib/services/meta/creator/lightspeed';
import {
  Invoice,
  InvoiceLine,
  InvoiceStatus,
  InvoiceTextEmail,
} from '@/models/invoice';
import { sleep, unwrapError, isProduction } from '@/lib/helpers';
import InvoiceCreatorConfig from '@/components/invoicecreator/config';
import {
  LightspeedReceipt,
  LightspeedRestaurantInterface,
  registerDummyLightspeedLibrary,
} from '@/lib/shims/lightspeed';

const AUTOCLOSE_DELAY: number = 5000;

/**
 * This component is loaded into a page which is loaded via the LS L series iPad app
 * (inside an iframe). Actually they make a POST request to our server which then
 * redirects us to this page. LS injects the LightspeedRestaurant object into the
 * window global.
 * LightspeedRestaurant gives us a startPayment callback where we can access the
 * receipt and the customer, it also provides a confirmPayment action which we can use
 * to mark the receipt as paid.
 * To aid local development, we have our own registerDummyLightspeedLibrary fn which
 * mimics what LS does in their app. One should just update the @/lib/shims/lightspeed
 * with some realistic values.
 */
declare var LightspeedRestaurant: LightspeedRestaurantInterface;

@Component({
  components: {
    InvoiceCreator,
    SendInvoiceModal,
  },
})
export default class LightspeedPage extends Vue {
  @Provide() creatorService = new LightspeedCreatorService();

  loading: boolean = true;
  errors: string[] = [];
  completed: boolean = false;
  invoice: Invoice = new Invoice();
  showSendModal: boolean = false;
  requestId: string;
  receipt: LightspeedReceipt;

  creatorConfig: InvoiceCreatorConfig = {
    disableVatChecker: true,
    disableIncludeVat: true,
    disableLineDelete: false,
    allowedTypes: [InvoiceStatus.DRAFT, InvoiceStatus.RECURRING],
    ghost: {},
  };

  async mounted(): Promise<void> {
    if (typeof LightspeedRestaurant === 'undefined') {
      if (isProduction()) {
        this.errors.push('Not in Lightspeed loyalty frame');
        return;
      }
      // this page wasn't loaded via the LS app (which loads the LS js lib)
      // but since it's a dev build - let's load our dummy LS js lib
      registerDummyLightspeedLibrary();
    }
    LightspeedRestaurant.callbacks.startPayment = async ({
      requestId,
      receipt,
    }) => {
      const jwt = this.$route.query.jwt;
      if (!jwt?.length || typeof jwt !== 'string') {
        this.errors.push('Lightspeed jwt not provided');
        return;
      }
      this.requestId = requestId;
      this.receipt = receipt;
      try {
        await this.creatorService.setup(
          receipt.companyId.toString(),
          receipt.receiptId,
          jwt,
        );

        await this.prepareInvoice();
      } catch (e) {
        const errorText = (e as any).response?.data?.error?.message;
        if (errorText) {
          this.errors.push(errorText);
        } else {
          this.errors.push(String(e));
        }
      } finally {
        this.loading = false;
      }
    };
  }

  async saveInvoice(invoice: Invoice): Promise<void> {
    try {
      const id = await this.creatorService.createInvoice(invoice);
      this.$toaster.success(this.$tc(`messages.success.created.invoice`), id);
      await this.complete();
    } catch (e) {
      this.$toaster.error('Error', this.$tc('messages.error.save.invoice'));
    }
  }

  async finishInvoice(
    type: string,
    invoice: Invoice,
    text: InvoiceTextEmail,
  ): Promise<boolean> {
    try {
      const id = await this.creatorService.createInvoice(invoice);
      if (!id) {
        this.$toaster.error('Error', this.$tc('messages.error.save.invoice'));
        return false;
      }

      await this.creatorService.sendMail(id, text);

      this.$toaster.success(
        this.$tc('messages.success.send.invoice.title'),
        this.$tc('messages.success.send.invoice.body'),
      );

      await this.complete();
      this.showSendModal = false;
      LightspeedRestaurant.actions.confirmPayment({
        amount: this.receipt.totalDue,
        tip: 0,
        requestId: this.requestId,
      });
      return true;
    } catch (e) {
      this.$toaster.error(
        this.$tc('messages.error.send.invoice'),
        unwrapError(e),
      );
      return false;
    }
  }

  private async complete() {
    this.completed = true;
    await sleep(AUTOCLOSE_DELAY);
    window.close();
  }

  private async prepareInvoice() {
    const data = this.creatorService.data;

    this.invoice.includesVat = data.vatInclusive ?? true;
    if (data.currency) {
      this.invoice.meta.currency = data.currency;
    }
    if (data.customers.match) {
      this.invoice.receiver = data.customers.match.toInvoiceReceiver();
    }
    this.creatorConfig.ghost!.customer = {
      name: data.customers.name,
      email: data.customers.email,
      showCreateNotification: !data.customers.match,
    };

    for (const item of data.items) {
      const line = new InvoiceLine(this.invoice.includesVat);

      line.quantity = new Big(item.amount);
      line.description = item.productName;
      line.price = new Big(item.unitPrice);
      line.vat =
        data.vat.options.find((e) => e.id === data.vat.match[item.id]) || null;
      const ledger = data.ledger.options.find(
        (e) => e.id === data.ledger.match[item.id],
      );
      line.ledger = ledger ? ledger.toInvoiceLedger() : null;

      if (line.ledger === null && data.ledger.options.length === 1) {
        // if we couldn't match the ledger but there's only one lets just pick that one
        line.ledger = data.ledger.options[0].toInvoiceLedger();
      }

      this.invoice.lines.push(line);
    }
  }
}
